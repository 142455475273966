<template>
    <div class="join">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-md-8">
                    <img src="@/assets/images/home/avatar.svg" class="join__image" alt="Tendencys" loading="lazy" />
                    <h2 class="join__title">
                        {{ $t('Are you ready to better manage your accounts?') }}
                    </h2>
                    <ui-button to="/signup">
                        {{ $t('Get started') }}
                    </ui-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'join',
    components: { UiButton },
};
</script>

<style lang="scss">
.join {
    padding-top: 100px;
    padding-bottom: 100px;

    &__title {
        font-weight: 600;
        font-size: 35px;
        color: #202225;
        margin-bottom: 30px;

        @media(max-width: 768px) {
            font-size: 28px;
            margin-bottom: 15px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        color: #787F84;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
